import { cardAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } =
	createMultiStyleConfigHelpers(cardAnatomy.keys);

const baseStyle = definePartsStyle({
	container: {
		backgroundColor: 'background.100',
		border: '1px solid #ccc',
	},
	header: {},
	body: {},
	footer: {},
});

const sizes = definePartsStyle({
	base: {
		container: {
			borderRadius: '0',
		},
	},
});

export const cardTheme = defineMultiStyleConfig({ baseStyle, sizes });
