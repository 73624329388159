import { defineStyle, defineStyleConfig } from '@chakra-ui/react';

const hcGreen = defineStyle({
	borderWidth: '2px', // change the width of the border
	borderStyle: 'solid', // change the style of the border
	borderColor: 'brand.700', // change the color of the border
	borderRadius: 0, // set border radius to 10
});

export const dividerTheme = defineStyleConfig({
	variants: { hcGreen },
});
