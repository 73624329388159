import { theme as proTheme } from '@chakra-ui/pro-theme';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';
import { background, extendTheme } from '@chakra-ui/react';
import { cardTheme } from './cardTheme';
import { dividerTheme } from './dividerTheme';

const theme = extendTheme(proTheme, {
	config: {
		cssVarPrefix: 'hppycdng',
		initialColorMode: 'light',
	},
	components: {
		Card: cardTheme,
		Divider: dividerTheme,
	},
	colors: {
		hppycdngBlack: '#1b1516',
		hppycdngGray: 'rgba(0, 0, 0, 0.03)',
		hppycdngBeige: '#dddddd',
		hppycdngPrimary1: '#3772FF',
		hppycdngPrimary2: '#9757D7',
		hppycdngPrimary3: '#EF466F',
		hppycdngPrimary4: '#45B26B',
		brand: {
			100: '#E6EBE8',
			200: '#CCD8D0',
			300: '#99B0A1',
			400: '#678972',
			500: '#346143',
			600: '#013A14',
			700: '#012E10',
			800: '#01230C',
			900: '#001708',
			1000: '#000C04',
		},

		background: {
			100: '#ffffff',
			200: '#efefef',
			300: '#cccccc',
			400: '#b3b3b3',
			500: '#999999',
			600: '#666666',
			700: '#4c4c4c',
			800: '#333333',
			900: '#191919',
			1000: '#000000',
		},
		hcgray: { 100: '#bfc5cc' },
		brandAlt: {
			100: '#FEB2B2',
		},
	},
	textStyles: {
		'hero-h1': {
			fontSize: ['40px', '100px'],
			fontWeight: 'bold',
			lineHeight: {
				base: '1.2',
				md: '1.1',
			},
			color: 'hppycdngBlack',
			letterSpacing: {
				base: '0px',
				md: '-3.5px',
			},
		},
		'hero-h2': {
			fontSize: ['22px', '60px'],
			fontWeight: 'bold',
			lineHeight: '1',
			color: 'hppycdngBlack',
			letterSpacing: '-2px',
		},
		'hero-subline': {
			lineHeight: '1.2',
			fontWeight: '400',
			maxW: '3xl',
			bgGradient: 'linear(to-b, #EE8057, #EE8057)',
			bgClip: 'text',
			letterSpacing: {
				base: '0px',
				md: '-1.1px',
			},
		},
		sectionHeadline: {
			fontSize: ['18px', '22px'],
			fontWeight: 500,
			color: '#86868b',
			letterSpacing: '-0.25px',
		},
		h1: {
			fontSize: ['48px', '64px'],
			fontWeight: '700',
			lineHeight: '1',
			letterSpacing: '-2.5px',
			color: 'hppycdngBlack',
		},
		h2: {
			fontSize: ['48px', '48px'],
			fontWeight: '700',
			lineHeight: '1',
			letterSpacing: '-1.8px',
			color: 'hppycdngBlack',
		},
		h3: {
			fontSize: ['40px', '40px'],
			fontWeight: '700',
			lineHeight: '1',
			letterSpacing: '-1.2px',
			color: 'hppycdngBlack',
		},
		p: {
			fontSize: ['16px', '22px'],
			fontWeight: '400',
			lineHeight: '1.4',
			letterSpacing: '-0.3px',
			color: 'hppycdngBlack',
		},
		span: {
			color: 'background.800',
		},
		'html, body': {
			fontSize: 'sm',
			color: '#222222',
			lineHeight: 'tall',
		},
		primary: {
			fontFamily: `Inter`,
		},
	},
	layerStyles: {
		base: {
			bg: 'background.100',
			border: '2px solid',
			color: '#000000',
			borderColor: 'background.100',
		},
		selected: {
			bg: 'teal.500',
			color: 'teal.700',
			borderColor: 'orange.500',
		},
	},
	fonts: {
		heading: `Inter, Verdana, -apple-system, system-ui, sans-serif`,
		body: `Inter, -apple-system, system-ui, sans-serif`,
		mono: `Roboto Mono, serif`,
	},
	styles: {
		global: {
			body: {
				fontFamily: `Inter, -apple-system, system-ui, sans-serif`,
			},
			'.scroll-container': {
				display: 'flex',
				justifyContent: 'space-between',
				maxWidth: '1200px',
				margin: '100px auto',
				overflow: 'hidden',
			},
			'.img-w-shadow': {
				width: '100%',
				height: 'auto',
				position: 'relative',
				zIndex: 1,
				verticalAlign: 'middle',
			},
			'.bottom-shadow': {
				background:
					'linear-gradient(90deg, rgba(1,35,12, 0) 0%, rgba(1,35,12, 1) 40%)',
				height: '100%',
				width: '100%',
				position: 'absolute',
				left: '0',
				top: '0',
				zIndex: 0,
			},
			'.top-shadow': {
				background:
					'linear-gradient(90deg, rgba(1,35,12, 0) 0%, rgba(1,35,12, 1) 25%)',
				height: '100%',
				width: '140%',
				position: 'absolute',
				left: '0',
				top: '0',
				zIndex: 2,
			},
			'.menu-item': {
				margin: '0 1.5rem',
				cursor: 'pointer',
				position: 'relative',
			},
			'.underline': {
				position: 'relative',
			},
			'.underline:not(.active)': {
				color: 'black',
			},
			'.underline.active': {
				color: 'black',
			},
			'.underline::after': {
				content: '""',
				position: 'absolute',
				width: '100%',
				transform: 'scaleX(0)',
				height: '4px',
				bottom: '-8px',
				left: '0',
				backgroundColor: 'black',
				transformOrigin: 'bottom right',
				transition: 'transform 0.25s ease-out',
			},

			'.underline:hover::after': {
				transform: 'scaleX(1)',
				transformOrigin: 'bottom left',
			},
			'.underline.active::after': {
				transform: 'scaleX(1)',
				transformOrigin: 'bottom left',
				backgroundColor: '#2b322a',
			},
			'.dropdownMenuItem:hover': {
				backgroundColor: 'hppycdngGray',
				borderRadius: '8.5px',
			},
			'.parallax': {
				overflow: 'hidden',
				letterSpacing: '-2px',
				lineHeight: '1.2',
				margin: '0',
				display: 'flex',
				flexWrap: 'nowrap',
			},
			'.parallax .scroller': {
				fontWeight: 'bold',
				textTransform: 'uppercase',
				fontSize: '1.5rem',
				display: 'flex',
				whiteSpace: 'nowrap',
				flexWrap: 'nowrap',
			},
			'.parallax > div': {
				display: 'block',
				marginRight: '30px',
			},
		},
	},
});

export default theme;
